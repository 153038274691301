import React from "react";
import TableOperators from "../../ImpostazioniPages/tabs/UtentiTab/TableOperators";
import {Grid} from "@mui/material";
import {UtentiAziendaProps} from "../../../type";

const UtentiAzienda: React.FC<UtentiAziendaProps> = ({ tenant }) => {

    return (
        <>
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={6}>
                    <TableOperators reloadTable={false} tenant={tenant} isDetailOfCompany={true} />
                </Grid>
            </Grid>
        </>
    );
}
export default UtentiAzienda;