import {useTranslation} from "react-i18next";
import profiloInitialValues from "../../../formModels/profilo/profiloInitialValues";
import {IRegistryProfileInitialValues} from "../../../../../types/registry";
import React, {useEffect, useState} from "react";
import {RegistryApiService} from "../../../../../services/RegistryApiService";
import {Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, Skeleton, TextField} from "@mui/material";
import {Form, Formik, FormikHelpers, FormikTouched} from "formik";
import profiloSchema from "../../../formModels/profilo/profiloSchema";
import {IAlert} from "../../../../../types/commons";
import {ProfiloProps} from "../../../../../type";
import {registryToProfileInitialValues} from "../../../../../utils/helperFunctions";
import {isDisabled} from "@testing-library/user-event/dist/utils";

const ProfiloCard: React.FC<ProfiloProps> = ({isDetailOfCompany = false, company}) => {
	const {t} = useTranslation();
	const [initialValues, setInitialValues] = useState<IRegistryProfileInitialValues>(profiloInitialValues);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
	const schema = profiloSchema;

	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "",
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});

	const registryApiService = new RegistryApiService();

	useEffect(() => {
		if (isDetailOfCompany && company) {
			setInitialValues(registryToProfileInitialValues(company));
			setIsLoading(false);
		} else {
			registryApiService
				.infoMeProfiloInitialValues()
				.then((response) => {
					setInitialValues(response);
				})
				.catch((err) => {
					console.error("errore", err);
				})
				.finally(() => {
					setIsLoading(false);
				});
		}
	}, []);

	useEffect(() => {
		if (isDetailOfCompany && company) {
			setInitialValues(registryToProfileInitialValues(company));
		}
	}, [company]);

	const skeletonComponent = () => {
		return (
			<Box marginY={1}>
				{[4, 2, 8, 7, 3, 10, 7].map((item, key) => (
					<Skeleton key={key} height={60} width={item * 10 + "%"} />
				))}
			</Box>
		);
	};

	const onSubmit = (values: IRegistryProfileInitialValues, actions: FormikHelpers<IRegistryProfileInitialValues>) => {
		setIsLoadingSubmit(true);
		const valuesToSend = {...values};
		delete valuesToSend.email;
		delete valuesToSend.business_name;

		registryApiService
			.patchInfoMe(valuesToSend)
			.then((response) => {
				if (response) {
					setAlert({...alert, show: true, severity: "success", message: "Dati cambiati con successo"});
				}
			})
			.catch((error) => {
				console.error(error);
				setAlert({...alert, show: true, severity: "error", message: error});
			})
			.finally(() => {
				setIsLoadingSubmit(false);
				actions.setSubmitting(false);
			});
	};

	return (
		<Card variant="outlined">
			<CardHeader title={t("SETTINGS.TAB_PROFILE.CARD_PROFILE.TITLE")}></CardHeader>
			<CardContent>
				{isLoading && skeletonComponent()}
				{!isLoading && (
					<Formik<IRegistryProfileInitialValues> initialValues={initialValues} enableReinitialize={true} validationSchema={schema} onSubmit={onSubmit}>
						{({handleSubmit, handleChange, values, setTouched, handleBlur, setFieldValue, touched, errors, isValid}) => {
							const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IRegistryProfileInitialValues>]);
							const isSubmitDisabled = !formIsTouched || !isValid;
							return (
								<Form noValidate onSubmit={handleSubmit}>
									<Grid container columnSpacing={3} rowSpacing={3}>
										<Grid item xs={12} sm={12}>
											{alert.show && (
												<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
													{t(alert.message)}
												</Alert>
											)}
										</Grid>
										<Grid item xs={12}>
											<TextField
												name="business_name"
												value={values.business_name}
												// onBlur={handleBlur}
												inputProps={{maxLength: 13, readOnly: true}}
												label={t("SETTINGS.TAB_PROFILE.CARD_PROFILE.BUSINESS_NAME")}
												fullWidth
												color="secondary"
												sx={{hover: {color: "red"}}}
												disabled
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="firstname"
												value={values.firstname}
												onChange={handleChange}
												onBlur={handleBlur}
												inputProps={{maxLength: 100}}
												label={t("SETTINGS.TAB_PROFILE.CARD_PROFILE.FIRSTNAME")}
												error={touched.firstname && !!errors.firstname}
												helperText={touched.firstname && errors.firstname ? t(errors.firstname) : ""}
												required
												fullWidth
												disabled={isDetailOfCompany}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="surname"
												value={values.surname}
												onChange={handleChange}
												onBlur={handleBlur}
												inputProps={{maxLength: 100}}
												label={t("SETTINGS.TAB_PROFILE.CARD_PROFILE.LASTNAME")}
												error={touched.surname && !!errors.surname}
												helperText={touched.surname && errors.surname ? t(errors.surname) : ""}
												required
												fullWidth
												disabled={isDetailOfCompany}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="phone"
												value={values.phone}
												onChange={handleChange}
												onBlur={handleBlur}
												inputProps={{maxLength: 13}}
												label={t("SETTINGS.TAB_PROFILE.CARD_PROFILE.PHONE")}
												error={touched.phone && !!errors.phone}
												helperText={touched.phone && errors.phone ? t(errors.phone) : ""}
												required
												fullWidth
												disabled={isDetailOfCompany}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="email"
												value={values.email}
												// onBlur={handleBlur}
												inputProps={{maxLength: 13, readOnly: true}}
												label={t("SETTINGS.TAB_PROFILE.CARD_PROFILE.EMAIL")}
												fullWidth
												color="secondary"
												disabled
											/>
										</Grid>

										<Grid item xs={12} textAlign="end">
											<Button
												type="submit"
												variant="contained"
												disableElevation
												color="secondary"
												disabled={isLoadingSubmit || isSubmitDisabled || isDetailOfCompany}
												size="large"
												startIcon={isLoadingSubmit ? <CircularProgress color="secondary" size={15} /> : null}
											>
												{t("SETTINGS.TAB_PROFILE.CARD_PROFILE.SUBMIT")}
											</Button>
										</Grid>
									</Grid>
								</Form>
							);
						}}
					</Formik>
				)}
			</CardContent>
		</Card>
	);
};

export default ProfiloCard;
