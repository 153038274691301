import {IApiCommonsFields} from "./commons";

export interface IAuthSignUp {
	email: string;
	password: string;
	terms: boolean;
	firstName: string;
	lastName: string;
	businessName: string;
	phone: string;
}

export interface IAuthSignIn {
	email: string;
	pwd: string;
	applicationName: "wb";
}

export interface IAuthSignInResponse {
	aToken: string;
	role: EAuthRole;
	username?: string;
}

export interface IAuthResetPasswordResponse {
	success: boolean;
}

export interface IResetPasswordPayload {
	pwd: string;
}

export interface IAuthRefreshToken {
	refreshToken: string;
}

export enum EAuthSessionData {
	ACCESS_TOKEN = "aToken",
	REFRESH_TOKEN_COOKIE = "refreshCookie",
	// @deprecated
	AUTH_DATA = "auth",
	USERNAME = "username",
	ROLE = "role",
	EXPIRED_SESSION = "expiredSession",
	SUCCESS_SIGNUP = "successSignup",
	HAVE_BILLING = "haveBilling",
	HAVE_SUBSCRIPTION = "haveSubscription",
	IS_MANUAL = "isManual",
	TENANT = "tenant"
}

export enum EAuthRole {
	ADMIN = "admin",
	SUPER_ADMIN = "superadmin",
	OPERATOR = "operator",
	PLATFORM_ADMIN = "platformadmin"
}

export type TAuthRole = EAuthRole.ADMIN | EAuthRole.SUPER_ADMIN | EAuthRole.OPERATOR | EAuthRole.PLATFORM_ADMIN | string;

export interface IValidateTenant {
	code: string;
}

export interface IAuthGroup extends IApiCommonsFields {
	pIva?: string;
	name: string;
	universalID: string;
	keyCode: string;
}

export interface IAuthForgotPasswordPayload {

}