import {createContext, useEffect, useState} from "react";
import {ICommonType} from "../types/commons";
import {IStatusType} from "../types/workflow";
import {IRegistryType} from "../types/registry";
import {CoreApiService} from "../services/CoreApiService";
import {RegistryApiService} from "../services/RegistryApiService";
import {WorkflowApiService} from "../services/WorkflowApiService";
import { EAuthSessionData } from "../types/auth";

export interface Type {
	_id: string;
	code: string;
	description?: string;
}

export interface StatusType {
	_id: string;
	state_transition: StateTransition;
}

interface StateTransition extends Type {
	state_from: string;
	state_to: string;
}

interface MyContextProps {
	reportType: ICommonType[];
	subjectType: ICommonType[];
	contactType: ICommonType[];
	stateTransitionType: IStatusType[];
	registryType: IRegistryType[];
	addressType: ICommonType[];
}

export const MyContext = createContext<MyContextProps | null>(null);

export const MyContextProvider = ({children}: {children: React.ReactNode}) => {
	const [reportTypeState, setReportTypeState] = useState<ICommonType[]>([]);
	const [subjectTypeState, setSubjectTypeState] = useState<ICommonType[]>([]);
	const [contactTypeState, setContactTypeState] = useState<ICommonType[]>([]);
	const [statusTypeState, setStatusTypeState] = useState<IStatusType[]>([]);
	const [registryTypeState, setRegistryTypeState] = useState<IRegistryType[]>([]);
	const [addressTypeState, setAddressTypeState] = useState<ICommonType[]>([]);

	const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
	const reportService = new CoreApiService();
	const registryService = new RegistryApiService();
	const workflowService = new WorkflowApiService();

	useEffect(() => {
		const fetchData = async () => {
			const reportType = await reportService.getReportTypes();
			const subjectType = await reportService.getInvolvedTypes();
			const contactType = await registryService.getContactType();
			const registryType = await registryService.getRegistryTypes();
			const addressType = await registryService.getRegistryAddressType();

			

			setReportTypeState(reportType);
			setSubjectTypeState(subjectType);
			setContactTypeState(contactType);
			setRegistryTypeState(registryType);
			setAddressTypeState(addressType);

			if (aToken) {
				const statusType = await workflowService.getTransitionRoles();
				setStatusTypeState(statusType);
			}
		};

		fetchData();
	}, []);

	return (
		<MyContext.Provider
			value={{
				reportType: reportTypeState,
				subjectType: subjectTypeState,
				contactType: contactTypeState,
				stateTransitionType: statusTypeState,
				registryType: registryTypeState,
				addressType: addressTypeState,
			}}
		>
			{children}
		</MyContext.Provider>
	);
};

export default MyContext;
