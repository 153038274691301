import React, {useEffect, useState} from "react";
import styles from "./TabellaSegnalazioniAdmin.module.css";
import {Table} from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import {useNavigate} from "react-router-dom";
import {t} from "i18next";
import axios from "axios";
import {TabSegnalatore, Translation} from "../../type";
import {API_GET_TAB, API_GET_TAB_REPORT_ADMIN} from "../../api/api";
import {Pagination} from "@mui/material";
import StatusHighlight from "../../compontensNuovi/StatusHighligt/StatusHighlight";
import SearchBar from "../../componets/barraRicerca/SearchBar";
import {daysAgo, formatDate} from "../../utils/helperFunctions";
import Footer from "../../componets/Footer/Footer";
import {CoreApiService} from "../../services/CoreApiService";
import {IReport} from "../../types/report";
import {WorkflowApiService} from "../../services/WorkflowApiService";
import {findTranslationByCode} from "../../utils/translationStateUtils";

const TabellaSegnalazioniAdmin: React.FC = () => {
	const navigate = useNavigate();
	const [reports, setReports] = useState<IReport[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchValue, setSearchValue] = useState("");
	const [translationStates, setTranslationStates] = useState<Translation[]>([]);

	const itemsPerPage = 20;
	const coreApiService = new CoreApiService();
	const workFlowService = new WorkflowApiService();

	const countData = async () => {
		try {
			const data = await coreApiService.countReports({
				searchFullText: searchValue,
				searchFields: ["reporter_name", "key_code", "fiscal_code", "_id"],
			});
			setTotalItems(data);
		} catch (error) {
			console.error(error);
		}
	};

	const fetchData = async () => {
		setIsLoading(true);
		try {
			const data = await coreApiService.getReports({
				page: currentPage,
				limit: itemsPerPage,
				orderBy: "createdAt",
				sort: -1,
				searchFullText: searchValue,
				searchFields: ["reporter_name", "key_code", "fiscal_code"],
				// key_code: searchValue,
			});
			setReports(data);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		countData();
		if (searchValue.length >= 3 || searchValue.length === 0) {
			fetchData();
		}
	}, [currentPage, searchValue]);

	const handleDoubleClick = (id: any) => {
		navigate(`/infoSegnalazione/${id}`);
	};

	useEffect(() => {
		workFlowService.stateTranslation({type: "state"}).then((response: any) => {
			setTranslationStates(response);
		});
	}, []);

	const handleSearch = (searchTerm: string) => {
		if (searchTerm.length >= 3) {
			setSearchValue(searchTerm.toLocaleLowerCase().trim());
			setCurrentPage(1);
		} else {
			setSearchValue("");
			setCurrentPage(1);
		}
	};

	const renderDate = (data: any) => {
		return (
			<div>
				<span>{formatDate(data)}</span>
				<span className={styles.daysAgoStyle}>
					({daysAgo(data)} {t("DAYS_AGO")})
				</span>
			</div>
		);
	};

	return (
		<>
			<Topbar />
			<div className={styles.labels}>
				<div className={styles.subTitle}>{t("FORM_REPORTS.TITLE")}</div>
				<div style={{marginBottom: "20px"}}>
					<SearchBar onSearch={handleSearch} placeholder={t("SEARCHBAR_PLACEHOLDER.TABLE_REPORTS")} />
				</div>
				{/* <div className={styles.tableContainer}> */}
				<Table hover>
					<thead style={{backgroundColor: " var(--grey-100)", color: "black", borderBottom: "2px var(--grey-100) solid"}}>
						<tr>
							<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.ID")}</th>
							<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.KEY_CODE")}</th>
							<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.CREATION_DATE")}</th>
							<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.REPORTER")}</th>
							<th className={styles.tableHeaders}>{t("TABLE_REPORTS.TABLE.STATUS")}</th>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={5} className={styles.loadingRow}>
									Loading...
								</td>
							</tr>
						) : reports.length === 0 ? (
							<tr>
								<td colSpan={5} className={styles.emptyMessage}>
									{searchValue ? t("SETTINGS.TAB_USERS.USER_TABLE.NO_MATCHING_RECORDS") : t("SETTINGS.TAB_USERS.USER_TABLE.NO_DATA")}
								</td>
							</tr>
						) : (
							reports.map((report, index) => {
								const rowId = ((currentPage - 1) * itemsPerPage + index + 1).toString().padStart(3, "0");
								return (
									<tr key={report._id} onDoubleClick={() => handleDoubleClick(report._id)} className={`${styles.rowStyle} ${styles.tableRow}`}>
										<td className={styles.rowStyle}>
											{rowId}
											{report.view && (
												<div
													style={{
														backgroundColor: "var(--newStatus)",
														width: "12px",
														height: "12px",
														borderRadius: "50%",
														display: "inline-block",
														marginLeft: "10px",
													}}
												></div>
											)}
										</td>
										<td className={styles.rowStyle}>{report.key_code}</td>
										<td className={styles.rowStyle}>{renderDate(report.createdAt)}</td>
										{/* <td className={styles.rowStyle}>{user.reporter_name ? `${user.reporter_name}` : t("ANONYMOUS")}</td> */}
										<td className={styles.rowStyle}>{report.reporter_name ? `${report.reporter_name}` : t("ANONYMOUS")}</td>
										<td className={styles.rowStyle} style={{width: "fit-content"}}>
											<StatusHighlight stato={report ? report.state : ""} displayText={report ? findTranslationByCode(report.state, translationStates) : ""} />
										</td>
									</tr>
								);
							})
						)}
					</tbody>
				</Table>
				{!isLoading ? (
					<Pagination
						count={Math.ceil(totalItems / itemsPerPage)}
						page={currentPage}
						onChange={(_, page) => setCurrentPage(page)}
						shape="rounded"
						className={styles.pagination}
					/>
				) : null}
			</div>
			{/* </div> */}
			<Footer />
		</>
	);
};

export default TabellaSegnalazioniAdmin;
