import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styles from "./ListaAdminAzienda.module.css";
import {Table} from "react-bootstrap";
import {Grid} from "@mui/material";
import Topbar from "../Topbar/Topbar";
import BackButton from "../../componets/Buttons/BackButton";
import AddButton from "../../componets/Buttons/AddButton";
import Footer from "../../componets/Footer/Footer";
import {RegistryApiService} from "../../services/RegistryApiService";
import {IRegistry} from "../../types/registry";
import { EAuthSessionData } from "../../types/auth";

const ListaAdminAzienda: React.FC = () => {
    const {_id} = useParams();
    const navigate = useNavigate();
    const decodedBusinessName = _id
        ? decodeURIComponent(_id)
        : "Nome azienda non disponibile";
    const [employees, setEmployees] = useState<IRegistry[]>([]);
    const [businessCode, setBusinessCode] = useState<string>("");
    const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
    const registryApiService = new RegistryApiService();

    useEffect(() => {
        const loadData = async () => {
            const employeeData = await getEmployeesForAzienda(decodedBusinessName);
            if (employeeData && employeeData.length > 0) {
                setEmployees(employeeData);  // Set the retrieved employees in the state
                const businessEmployees = employeeData.filter(
                    (employee: any) => employee.business_name === decodedBusinessName
                );
                const ownerIds = businessEmployees.map(
                    (employee: any) => employee.id_registry_owner
                );
                if (businessEmployees.length > 0) {
                    if (typeof businessEmployees[0].id_registry_owner === "string") {
                        setBusinessCode(businessEmployees[0].id_registry_owner);
                    } else {
                        setBusinessCode(businessEmployees[0].id_registry_owner?._id ?? '');
                    }

                }
            }
        };
        loadData();
    }, [decodedBusinessName]);


    const getEmployeesForAzienda = async (aziendaName: any) => {
        try {
            return await registryApiService.getRegistryDetail(decodedBusinessName, { onlyEmployee: true });
        } catch (error) {
            console.error(error);
            const res: IRegistry[] = [];
            return res;
        }
    };

	const handleBackClick = () => {
		navigate(`/Homepage`);
	};

	const handleAddUtente = () => {
		navigate(`/AddUtenteNew/${_id}`);
	};

    return (
        <>
            <Grid sx={{marginTop: 1}}>
                <Topbar/>
            </Grid>
            <div className={styles.labels}>
                <div className={styles.subTitle}>
                    Lista Gestori
                    <AddButton onClick={handleAddUtente} text="Aggiungi Gestore"/>
                </div>
                <Table hover>
                    <thead
                        style={{
                            backgroundColor: "var(--grey-100)",
                            color: "black",
                            borderBottom: "2px var(--grey-100) solid",
                        }}
                    >
                    <tr>
                        <th className={styles.tableHeaders}>Nome</th>
                        <th className={styles.tableHeaders}>Cognome</th>
                        <th className={styles.tableHeaders}>Email</th>
                        <th className={styles.tableHeaders}>Professione</th>
                    </tr>
                    </thead>
                    <tbody>
                    {employees && employees.length > 0 ? (
                        employees.map((employee: IRegistry) => (
                            <tr key={employee._id}>
                                <td>{employee.firstname}</td>
                                <td>{employee.surname}</td>
                                <td>{employee.id_contact[0].contact}</td>
                                <td>{employee.profession}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={4}>Nessun Gestore trovato.</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                <BackButton onClick={handleBackClick}/>
            </div>
            <Footer/>
        </>
    );
};

export default ListaAdminAzienda;
