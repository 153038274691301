import {object, string} from "yup";
import {intlPhoneRegex} from "../../../../utils/regex";
import {IRegistryProfileInitialValues} from "../../../../types/registry";

export default object<IRegistryProfileInitialValues>().shape({
	// email: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
	phone: string().trim().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
	firstname: string().trim().required("ERRORS.FORM.REQUIRED"),
	surname: string().trim().required("ERRORS.FORM.REQUIRED"),
});
