import {useNavigate} from "react-router";
import BasicButton from "../../compontensNuovi/buttons/BasicButton";
import ButtonTextField from "../../compontensNuovi/buttons/ButtonTextField";
import styles from "./VisualizzazineAnonima.module.css";
import {useTranslation} from "react-i18next";
import {Fragment, useEffect, useState} from "react";
import {toast_error} from "../../utils/custom-toast";
import {ToastContainer} from "react-toastify";
import {useParams} from "react-router-dom";
import {CoreApiService} from "../../services/CoreApiService";
import { EAuthSessionData } from "../../types/auth";

const VisualizzazioneAnonima = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [keyCode, setKeyCode] = useState("");
    const {tenant} = useParams();

    const reportApiService = new CoreApiService();

    useEffect(() => {
        sessionStorage.removeItem(EAuthSessionData.ACCESS_TOKEN);
    }, []);

    const handleClickNewReport = () => {
        navigate("/segnalazione", {state: {tenant: tenant}});
    };

    const handleTextChange = (text: string) => {
        setKeyCode(text);
    };

    const handleCheckReport = async () => {
        if (keyCode === "") {
            toast_error("Inserisci un keyCode valido");
            return;
        }

        try {
            const response = await reportApiService.getInfoReportUser(keyCode, tenant);

            if (response) {
                navigate(`/infoSegnalazione/${keyCode}`);
            }
        } catch (error: any) {
            toast_error(error);
        }
    };

    return (
        <>
            <ToastContainer/>
            <BasicButton text={t("LANDINGPAGE.BUTTON_SEND_REPORT")} style={{
                fontSize: "16px",
            }} onClick={handleClickNewReport}/>
            <div className={styles.labels}>
                {t("LANDINGPAGE.LABEL_CHECK_REPORT_1")}
                <br/>
                {t("LANDINGPAGE.LABEL_CHECK_REPORT_2")}
            </div>
            <ButtonTextField text={t("SIGN_IN")} onTextChange={handleTextChange} onButtonClick={handleCheckReport}/>
        </>
    );
};

export default VisualizzazioneAnonima;
