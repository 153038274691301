import {Box, Divider} from "@mui/material";
import {t} from "i18next";
import React, {useEffect, useMemo, useState} from "react";
import i18n from "../../i18n";
import {IRegistry} from "../../types/registry";

import {RegistryApiService} from "../../services/RegistryApiService";
import {LinkApiService} from "../../services/LinkService";
import { EAuthSessionData } from "../../types/auth";
import { useSelector } from "react-redux";

export interface linksBE {
	_id: string;
	title: string;
	link: string;
	generic: boolean;
	lang: string;
	createdAt: string;
	updatedAt: string;
}

const Footer = () => {
	//TODO : TRANSLATE

	const aToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
	const [linksBE, setLinksBE] = useState<linksBE[]>([]);
	const [admin, setAdmin] = useState<Partial<IRegistry>>();
	const tenant = sessionStorage.getItem("tenant");
	const role = useSelector((state: any) => state.user.role);

	const registryApiService = new RegistryApiService();
	const linkApiService = new LinkApiService();

	// TODO: Effettuare await?
	useEffect(() => {
		if (aToken) {
			registryApiService.infoMe().then((response) => {
				setAdmin(response);
				sessionStorage.setItem("tenant", response?.tenant);
			});
		}
	}, []);

	useEffect(() => {
		linkApiService.linkByTenantPrivacy(tenant!).then((response) => {
			setLinksBE(response);
		});
	}, []);

	return (
		<Box marginLeft="3%" marginRight="3%" marginY="50px">
			<Box marginY="20px">
				<Divider />
			</Box>
			<footer className="footer">
				<Box sx={{width: "100%"}} textAlign="end" color="grey.300">
					{linksBE.map((link, index) => (
						<Box key={index} display="inline-block" marginX="5px">
							<a
								style={{
									fontSize: "9px",
								}}
								href={link.link}
								target="_blank"
								rel="noreferrer"
								title={t(`FOOTER.${link.title}`)}
							>
								{t(`FOOTER.${link.title}`)}
							</a>
							{index < linksBE.length - 1 && " | "}
						</Box>
					))}
				</Box>
			</footer>
		</Box>
	);
};

export default Footer;
