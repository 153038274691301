import {EAuthSessionData} from "../types/auth";
import Cookies from "js-cookie";

function fn(): boolean {
    const hasToken = sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN);
    //const hasRefreshToken = Cookies.get(EAuthSessionData.REFRESH_TOKEN_COOKIE);
    return !(hasToken);
}

export default fn;
