import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    Grid,
    Skeleton,
    TextField
} from "@mui/material";
import {Form, Formik, FormikErrors, FormikHelpers, FormikTouched} from "formik";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {IRegistryCompanyInitialValues} from "../../../types/registry";
import {RegistryApiService} from "../../../services/RegistryApiService";
import {useTranslation} from "react-i18next";
import aziendaInitialValues from "../formModels/azienda/aziendaInitialValues";
import italianProvinces from "../../../utils/italianProvinces";
import europeanCountryCodes from "../../../utils/europeanCountryCodes";
import aziendaSchema from "../formModels/azienda/aziendaSchema";
import {IAlert} from "../../../types/commons";

const AziendaTab = () => {
    const {t} = useTranslation();
    const [aInitialValues, setAInitialValues] = useState<IRegistryCompanyInitialValues>(aziendaInitialValues);
    const registryApiService = new RegistryApiService();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [displayField, setDisplayField] = useState<boolean>(true);
    const [alert, setAlert] = useState<IAlert>({
        show: false,
        severity: "error",
        message: "",
        dismissible: true,
        onClose: () => {
            setAlert({...alert, show: false})
        }
    });
    const provincesList = italianProvinces;
    const countryList = europeanCountryCodes;
    const schema = aziendaSchema;

    useEffect(() => {
        registryApiService
            .infoCompanyMeInitialValues()
            .then((response) => {
                if (response.country !== 'IT') {
                    setDisplayField(false);
                    response.province = 'EE';
                    response.postal_code = '00000';
                }
                setAInitialValues(response);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error("error", e);
            });
    }, []);

    const skeletonComponent = () => {
        return (
            <Box marginY={1}>
                {[4, 2, 8, 7, 3, 10, 7].map((item, key) => (
                    <Skeleton key={key} height={60} width={item * 10 + '%'}/>
                ))}
            </Box>
        )
    }

    const onChangeCountry = (newValue: string | null, setFieldValue: Function, setTouched: (touched: FormikTouched<IRegistryCompanyInitialValues>, shouldValidate?: boolean) => Promise<void | FormikErrors<IRegistryCompanyInitialValues>>) => {
        setFieldValue('country', newValue);
        setTouched({country: true}).then();
        if (newValue !== 'IT') {
            setFieldValue('postal_code', '00000');
            setFieldValue('province', 'EE');
            setDisplayField(false);
        } else {
            setFieldValue('postal_code', '');
            setFieldValue('province', '');
            setDisplayField(true);
        }
    }


    const onSubmit = (
        values: IRegistryCompanyInitialValues,
        actions: FormikHelpers<IRegistryCompanyInitialValues>
    ) => {
        setIsLoadingSubmit(true);
        registryApiService
            .updateCompanyMe(values)
            .then((response) => {
                setIsLoadingSubmit(false);
                setAlert({
                    show: true,
                    severity: "success",
                    message: t("SETTINGS.TAB_COMPANY.SUBMIT_SUCCESS"),
                    dismissible: true,
                    onClose: () => {
                        setAlert({...alert, show: false})
                    }
                });
            }).catch((e) => {
            setIsLoadingSubmit(false);
            setAlert({
                show: true,
                severity: "error",
                message: t("SETTINGS.TAB_COMPANY.SUBMIT_ERROR"),
            });
        });
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    {alert.show && (
                        <Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
                            {t(alert.message)}
                        </Alert>
                    )}
                    <Card variant="outlined">
                        <CardHeader
                            title={t("SETTINGS.TAB_COMPANY.TITLE")}
                        ></CardHeader>
                        <CardContent>
                            {isLoading && skeletonComponent()}
                            {!isLoading &&
                                <Formik<IRegistryCompanyInitialValues> initialValues={aInitialValues}
                                                                       validationSchema={schema}
                                                                       onSubmit={onSubmit}>
                                    {({
                                          handleSubmit,
                                          handleChange,
                                          values,
                                          setTouched,
                                          handleBlur,
                                          setFieldValue,
                                          touched,
                                          errors,
                                          isValid,
                                      }) => {
                                        const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IRegistryCompanyInitialValues>]);
                                        const isSubmitDisabled = !formIsTouched || !isValid;
                                        return (
                                            <Form noValidate onSubmit={handleSubmit}>
                                                <Grid container columnSpacing={3} rowSpacing={3}>
                                                    <Grid item xs={12}>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={countryList}
                                                            style={{width: 200}}
                                                            disableClearable
                                                            value={values.country}
                                                            onChange={(_: SyntheticEvent, newValue: string | null) => onChangeCountry(newValue, setFieldValue, setTouched)}
                                                            renderInput={(params) =>
                                                                <TextField {...params} required name="country"
                                                                           label={t('SETTINGS.TAB_COMPANY.COUNTRY')}/>}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="business_name"
                                                            value={values.business_name}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            inputProps={{maxLength: 40}}
                                                            required
                                                            error={!!errors.business_name}
                                                            helperText={t(errors.business_name ?? "")}
                                                            label={t("SETTINGS.TAB_COMPANY.BUSINESS_NAME")}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            name="website"
                                                            value={values.website}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            inputProps={{maxLength: 100}}
                                                            required
                                                            error={!!errors.website}
                                                            helperText={t(errors.website ?? "")}
                                                            label={t("SETTINGS.TAB_COMPANY.WEBSITE")}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={displayField ? 4 : 6}>
                                                        <TextField
                                                            name="street"
                                                            value={values.street}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!errors.street}
                                                            helperText={t(errors.street ?? '')}
                                                            inputProps={{maxLength: 100}}
                                                            required
                                                            label={t('SETTINGS.TAB_COMPANY.STREET')} fullWidth/>
                                                    </Grid>
                                                    {displayField && <Grid item xs={12} md={2}>
                                                        <TextField
                                                            name="postal_code"
                                                            value={values.postal_code}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            error={!!errors.postal_code}
                                                            helperText={t(errors.postal_code ?? '')}
                                                            inputProps={{maxLength: 5}}
                                                            required
                                                            label={t('SETTINGS.TAB_COMPANY.POSTAL_CODE')}
                                                            fullWidth/>
                                                    </Grid>}
                                                    <Grid item xs={12} md={displayField ? 4 : 6}>
                                                        <TextField
                                                            name="city"
                                                            value={values.city}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            inputProps={{maxLength: 50}}
                                                            required
                                                            error={!!errors.city}
                                                            helperText={t(errors.city ?? "")}
                                                            label={t("SETTINGS.TAB_COMPANY.CITY")}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    {displayField && <Grid item xs={12} md={2}>
                                                        <Autocomplete
                                                            disablePortal
                                                            readOnly={isLoadingSubmit}
                                                            options={provincesList}
                                                            fullWidth
                                                            disableClearable
                                                            onBlur={() => setTouched({province: true})}
                                                            value={values.province}
                                                            onChange={(_: SyntheticEvent, newValue: string | null) => setFieldValue('state', newValue)}
                                                            renderInput={(params) =>
                                                                <TextField {...params} error={!!errors.province}
                                                                           required
                                                                           name="province"
                                                                           helperText={t(errors.province ?? '')}
                                                                           label={t('SETTINGS.TAB_COMPANY.PROVINCE')}/>}
                                                        />
                                                    </Grid>}
                                                    <Grid item xs={12} textAlign="end">
                                                        <Button type="submit" variant="contained"
                                                                disableElevation
                                                                color="secondary"
                                                                disabled={isLoadingSubmit || isSubmitDisabled}
                                                                size="large"
                                                                startIcon={isLoadingSubmit ?
                                                                    <CircularProgress color="secondary"
                                                                                      size={15}/> : null}>
                                                            {t('SETTINGS.TAB_COMPANY.SUBMIT')}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )
                                    }}
                                </Formik>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default AziendaTab;
