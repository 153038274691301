import React, {useState} from "react";
import {Form, Modal} from "react-bootstrap";

import {Alert, Link} from "@mui/material";
import {useTranslation} from "react-i18next";
import BasicButton from "../../compontensNuovi/buttons/BasicButton";
import {IAlert} from "../../types/commons";
import {AuthApiService} from "../../services/AuthApiService";

const RecuperaPassword: React.FC = () => {
	const {t} = useTranslation();
	const [show, setShow] = useState(false);
	const [email, setEmail] = useState<string>("");
	const [validated, setValidated] = useState(false);
	// const [emailError, setEmailError] = useState("");

	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "",
		// dismissible: true,
		// onClose: () => {
		// 	setAlert({...alert, show: false});
		// },
	});
	const authApiService = new AuthApiService();

	const handleClose = () => {
		setShow(false);
		setAlert({
			show: false,
			severity: "error",
			message: "",
		});
	};

	const handleShow = () => setShow(true);

	const handlePasswordRecovery = async (email: string) => {
		let requestBody;
		requestBody = {
			email: email,
		};
		try {
			const data = await authApiService.forgotPassword(requestBody);
			if (data) {
				setAlert({...alert, show: true, severity: "success", message: "OPERATION_SUCCESS"});
				setTimeout(() => setShow(false), 2000);
			} else {
				setAlert({...alert, show: true, severity: "error", message: "OPERATION_ERROR"});
			}
		} catch (error: any) {
			setAlert({...alert, show: true, message: error});
		}
	};

	const handleSubmit = (event: any) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		}
		setValidated(true);
		if (email) {
			handlePasswordRecovery(email);
			// handleClose();
			setEmail("");
		}
	};

	return (
		<>
			<Link
				sx={{
					textDecoration: "none",
				}}
				style={{cursor: "pointer"}}
				onClick={handleShow}
			>
				{t("LOGIN.RECOVER_PASSWORD")}
			</Link>
			<Modal animation={false} show={show} onHide={handleClose} onEntered={() => setShow(true)}>
				{/* <ToastContainer /> */}
				<Modal.Header closeButton>
					<Modal.Title style={{fontSize: "18px", fontWeight: "700"}}>{t("RESET_PASSWORD")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form noValidate validated={validated} onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="exampleForm.email">
							{alert.show && (
								<Alert className="mb-3" severity={alert.severity}>
									{t(alert.message)}
								</Alert>
							)}
							<Form.Label style={{fontSize: "15px"}}>{t("EMAIL")}</Form.Label>
							<Form.Control style={{fontSize: "13px"}} type="email" placeholder="name@example.com" onChange={(e) => setEmail(e.target.value)} />
							{/* <Form.Control.Feedback type="invalid">{emailError}</Form.Control.Feedback> */}
						</Form.Group>
						<Modal.Footer>
							<BasicButton text={t("CONFIRM")} maxWidth="fit-content" type="submit" />
							<BasicButton
								onClick={handleClose}
								text={t("CLOSE")}
								maxWidth="fit-content"
								type="button"
								backgroundColor="trasparent"
								backgroundColorHover="var( --grey-500)"
								borderColorHover="var( --grey-500)"
								borderColor="black"
							/>
						</Modal.Footer>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default RecuperaPassword;
