import React, {useEffect, useState} from "react";
import {FormControlLabel, FormHelperText, Radio, RadioGroup, TextField} from "@mui/material";
import styles from "./TabDue.module.css";
import {t} from "i18next";
import {radioStyleSX} from "../../../assets/CustomStyles";

type TimePeriod = "ongoing" | "concluded";

const TabDue = ({updateReportField, handleTabCompletion, tabIndex}: any) => {
	const [period, setPeriod] = useState<TimePeriod | null>(null);
	const [startDate, setStartDate] = useState<string>("");
	const [endDate, setEndDate] = useState<string>("");
	const [startDateError, setStartDateError] = useState<boolean>(false);
	const [endDateError, setEndDateError] = useState<boolean>(false);
	const [helperText, setHelperText] = useState<string>("");
	const [today, setToday] = useState<string>("");

	useEffect(() => {
		if (period === null) {
			handleTabCompletion(tabIndex, true);
		}
	}, [period, tabIndex, handleTabCompletion]);

	const getCurrentDate = () => {
		const today = new Date();
		setToday(today.toISOString().split("T")[0]);
		const yyyy = today.getFullYear();
		const mm = String(today.getMonth() + 1).padStart(2, "0");
		const dd = String(today.getDate()).padStart(2, "0");
		return `${yyyy}-${mm}-${dd}`;
	};

	const handlePeriodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value as TimePeriod;
		setPeriod(value);
		let isValid = true;

		if (value === "ongoing") {
			setEndDate("");
			setEndDateError(false);
		}

		if (!isStartDateValid(startDate)) {
			setStartDateError(true);
			isValid = false;
		}

		if (value === "concluded" && !isEndDateValid(endDate)) {
			setEndDateError(true);
			isValid = false;
		}

		updateReportField({
			time_period_ended: value === "concluded",
			end_date: value === "concluded" ? endDate : "",
		});

		handleTabCompletion(tabIndex, isValid);
	};

	const handleStartDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newStartDate = event.target.value;
		if (isStartDateValid(newStartDate)) {
			setStartDate(newStartDate);
			setStartDateError(false);
			if (isEndDateValid(endDate)) {
				setEndDateError(false); // Clear error when valid end date
				updateReportField({
					start_date: newStartDate,
					end_date: endDate,
				});
				handleTabCompletion(tabIndex, true);
			}
		} else {
			setStartDate("");
			setStartDateError(true);
			updateReportField({
				start_date: "",
			});
			handleTabCompletion(tabIndex, false);
		}
	};

	const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newEndDate = event.target.value;
		if (isEndDateValid(newEndDate)) {
			setEndDate(newEndDate);
			setEndDateError(false);
			if (isStartDateValid(startDate)) {
				setStartDateError(false); // Clear error when valid start date
				updateReportField({
					start_date: startDate,
					end_date: newEndDate,
				});
				handleTabCompletion(tabIndex, true);
			}
		} else {
			setEndDate("");
			setEndDateError(true);
			updateReportField({
				end_date: "",
			});
			handleTabCompletion(tabIndex, false);
		}
	};

	const isStartDateValid = (date: string) => {
		if (date > getCurrentDate()) {
			setHelperText("La data di inizio non può essere posteriore alla data odierna");
			return false;
		}
		if (endDate && date > endDate) {
			setHelperText("La data di inizio non può essere successiva alla data di fine");
			return false;
		}
		setHelperText("");
		return true;
	};

	const isEndDateValid = (date: string) => {
		if (date < startDate) {
			setHelperText("La data di fine non può essere precedente alla data di inizio");
			return false;
		}
		if (date > getCurrentDate()) {
			setHelperText("La data di fine non può essere posteriore alla data odierna");
			return false;
		}
		setHelperText("");
		return true;
	};

	// const shouldShowError = (startDateError && startDate !== "") || (endDateError && endDate !== "");

	const isBothInputsDisabled = period === null;
	const isEndDateInputDisabled = period === "ongoing";

	return (
		<>
			<div>
				<RadioGroup name="period" value={period} onChange={handlePeriodChange} row>
					<FormControlLabel value="ongoing" control={<Radio sx={radioStyleSX} />} label={t("FORM_REPORTS.SECOND_TAB.LABEL_ONGOING")} />
					<FormControlLabel value="concluded" control={<Radio sx={radioStyleSX} />} label={t("FORM_REPORTS.SECOND_TAB.LABEL_CONCLUDED")} />
				</RadioGroup>
			</div>

			<div className={styles.dateInput}>
				<div style={{display: "flex", flexDirection: "row", gap: "10px"}}>
					<TextField
						className={`${isBothInputsDisabled ? styles.dataInputDisabled : styles.dataInput}`}
						type="date"
						value={startDate}
						onChange={handleStartDateChange}
						required
						// error={shouldShowError}
						disabled={isBothInputsDisabled}
						inputProps={{max: today}}
					/>
					<TextField
						className={`${isEndDateInputDisabled || isBothInputsDisabled ? styles.dataInputDisabled : styles.dataInput}`}
						type="date"
						value={endDate}
						onChange={handleEndDateChange}
						required
						// error={shouldShowError}
						disabled={isBothInputsDisabled || isEndDateInputDisabled}
						inputProps={{max: today}}
					/>
				</div>
				<div>
					{/* {shouldShowError && ( */}
					<FormHelperText sx={{fontSize: "1rem"}} error>
						{helperText}
					</FormHelperText>
					{/* )} */}
				</div>
			</div>
		</>
	);
};

export default TabDue;
