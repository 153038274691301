import {IRegistryCompanyInitialValues} from "../../../../types/registry";

export default {
    business_name: "",
    website: "",
    postal_code: "",
    city: "",
    province: "",
    country: "",
    street: "",
} as IRegistryCompanyInitialValues;
