import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import BasicButton from "../../../compontensNuovi/buttons/BasicButton";
import sharedStyles from "../components/AuthDefaultLayout/Shared.module.css";
import {useTranslation} from "react-i18next";
import {Button, Form, InputGroup, Spinner} from "react-bootstrap";
import AuthDefaultLayout from "../components/AuthDefaultLayout/AuthDefaultLayout";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import RecuperaPassword from "../../RecuperaPassword/RecuperaPassword";
import {Alert, Divider, Grid} from "@mui/material";
import {Link, useLocation} from "react-router-dom";
import {IAlert} from "../../../types/commons";
import {AuthApiService} from "../../../services/AuthApiService";
import {EAuthSessionData, IAuthSignIn} from "../../../types/auth";
import {Formik, FormikTouched} from "formik";
import signInSchema from "../formModels/signInSchema";
import signInInitialValues from "../formModels/signInInitialValues";
import { useDispatch, useSelector } from "react-redux";
import { updateHaveSubscription, updateIsManual, updateRole, updateUsername } from "../../../redux/userSlice";

const SignInPage = () => {
	const {t} = useTranslation();
	const [mostraPwd, setMostraPwd] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "SIGN_IN_PAGE.SUBMIT_ERROR",
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});
	const authApiService = new AuthApiService();
	const navigate = useNavigate();

	const schema = signInSchema;
	const initialValues = signInInitialValues;

	const expiredSession = sessionStorage.getItem(EAuthSessionData.EXPIRED_SESSION);
	const successSignUp = sessionStorage.getItem(EAuthSessionData.SUCCESS_SIGNUP);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const successVerification = searchParams.get("successVerification");
	
	// REDUX
	const role = useSelector((state: any) => state.user.role); 
	const dispatch = useDispatch();

	useEffect(() => {
		if (expiredSession) {
			sessionStorage.removeItem(EAuthSessionData.EXPIRED_SESSION);
			setAlert({...alert, show: true, message: expiredSession, severity: "error"});
		}

		if (successSignUp) {
			sessionStorage.removeItem(EAuthSessionData.SUCCESS_SIGNUP);
			setAlert({...alert, show: true, message: successSignUp, severity: "info"});
		}

		if (successVerification) {
			setAlert({...alert, show: true, message: "SIGN_IN_PAGE.SUCCESS_EMAIL_VERIFICATION", severity: "success"});
		}
	}, []);

	const onSubmit = (values: IAuthSignIn) => {
		setIsLoading(true);
		authApiService
			.signIn(values)
			.then((response) => {
				dispatch(updateHaveSubscription(response.haveSubscription));
				dispatch(updateIsManual(response.isManual));
				dispatch(updateRole(response.role));
				dispatch(updateUsername(response.username));
				navigate("/route-switch");
				setIsLoading(false);
			})
			.catch((error: any) => {
				if (error === "ERRORS.GENERIC") {
					setAlert({ ...alert, show: true, message: "SIGN_IN_PAGE.SUBMIT_ERROR", severity: "error" });
				} else {
					setAlert({ ...alert, show: true, message: error, severity: "error" });
				}
				
				setIsLoading(false);
			});
	};

	return (
		<AuthDefaultLayout>
			<div className={sharedStyles.labels}>{"Login"}</div>
			<div className={sharedStyles.container}>
				{alert.show && (
					<Alert className="mb-3" severity={alert.severity} onClose={alert.onClose}>
						{t(alert.message)}
					</Alert>
				)}
				<Formik<IAuthSignIn> initialValues={initialValues} validationSchema={schema} onSubmit={onSubmit}>
					{({handleSubmit, handleChange, handleBlur, values, errors, touched, isValid}) => {
						const formIsTouched = Object.keys(touched).some((field) => touched[field as keyof FormikTouched<IAuthSignIn>]);
						const isSubmitDisabled = !formIsTouched || !isValid;
						return (
							<Form onSubmit={handleSubmit} noValidate>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroup}>
										<Form.Control
											name="email"
											placeholder="Email"
											value={values.email}
											onChange={handleChange}
											isInvalid={touched.email && !!errors.email}
											onBlur={handleBlur}
											readOnly={isLoading}
										/>
										<Form.Control.Feedback type="invalid">{errors.email ? t(errors.email) : ""}</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>
								<Form.Group>
									<InputGroup className={sharedStyles.inputGroupWithButton}>
										<Form.Control
											name="pwd"
											type={mostraPwd ? "text" : "password"}
											placeholder="Password"
											value={values.pwd}
											onChange={handleChange}
											maxLength={18}
											isInvalid={touched.pwd && !!errors.pwd}
											onBlur={handleBlur}
											readOnly={isLoading}
										/>
										<Form.Control.Feedback type="invalid">{errors.pwd ? t(errors.pwd) : ""}</Form.Control.Feedback>
										<Button onClick={() => setMostraPwd(!mostraPwd)} variant={"outline-light"}>
											{mostraPwd ? <VisibilityOff></VisibilityOff> : <Visibility></Visibility>}
										</Button>
									</InputGroup>
								</Form.Group>
								<BasicButton text={!isLoading ? t("SIGN_IN") : ""} maxWidth={"100%"} disabled={isSubmitDisabled || isLoading}>
									{isLoading ? <Spinner animation="border" /> : null}
								</BasicButton>
							</Form>
						);
					}}
				</Formik>
				<Grid container className={"mt-5 text-center"}>
					<Grid item xs={12} className={"mb-3"}>
						<Divider className={sharedStyles.divider}></Divider>
					</Grid>
					<Grid item xs={12} sm={6}>
						<RecuperaPassword />
					</Grid>
					<Grid item xs={12} sm={6}>
						<Link to={"../sign-up"}>{t("LOGIN.REGISTER_NOW")}</Link>
					</Grid>
				</Grid>
			</div>
		</AuthDefaultLayout>
	);
};

export default SignInPage;
