import {boolean, object, string} from "yup";
import {intlPhoneRegex} from "../../utils/regex";

export default object().shape({
	business_name: string()
		.trim()
		.required("ERRORS.FORM.REQUIRED")
		.min(2, "ERRORS.FORM.INVALID_BUSINESSNAME")
		.max(40, "ERRORS.FORM.INVALID_BUSINESSNAME"),
	vat_number: string().trim().required("ERRORS.FORM.REQUIRED").min(2, "ERRORS.FORM.INVALID_VAT_NUMBER").max(16, "ERRORS.FORM.INVALID_VAT_NUMBER"),
	address: string().trim().required("ERRORS.FORM.REQUIRED"),
	number: string().trim().required("ERRORS.FORM.REQUIRED"),
	city: string().trim().required("ERRORS.FORM.REQUIRED"),
	province: string().trim().required("ERRORS.FORM.REQUIRED"),
	postal_code: string().trim().required("ERRORS.FORM.REQUIRED"),
	state: string().trim().required("ERRORS.FORM.REQUIRED"),
	phone: string().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE").trim("ERRORS.FORM.INVALID_PHONE"),
	email: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
  website: string().trim().required("ERRORS.FORM.REQUIRED"),
	terms: boolean().oneOf([true], "ERRORS.FORM.REQUIRED"),
});
