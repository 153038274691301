import {object, string} from "yup";
import italianProvinces from "../../../../utils/italianProvinces";
import europeanCountryCodes from "../../../../utils/europeanCountryCodes";
import {IRegistryCompanyInitialValues} from "../../../../types/registry";

export default object<IRegistryCompanyInitialValues>().shape({
    country: string().trim().required("ERRORS.FORM.REQUIRED")
        .oneOf(europeanCountryCodes, "ERRORS.FORM.SELECT_ELEMENT_OF_LIST"),
    city: string().trim().required("ERRORS.FORM.REQUIRED").min(3).max(50),
    province: string().trim().required("ERRORS.FORM.REQUIRED")
        .oneOf([...italianProvinces, ...['EE']], "ERRORS.FORM.SELECT_ELEMENT_OF_LIST"),
    postal_code: string().trim().required("ERRORS.FORM.REQUIRED")
        .matches(/^\d{5}$/, "ERRORS.FORM.INVALID_POSTAL_CODE"),
    street: string().trim().required("ERRORS.FORM.REQUIRED").max(100),
    business_name: string().trim().required("ERRORS.FORM.REQUIRED").max(100),
    website: string().trim().required("ERRORS.FORM.REQUIRED").max(100),
})
