import {boolean, object, string} from "yup";
import {intlPhoneRegex} from "../../../../../utils/regex";

export default object().shape({
	firstname: string().trim().required("ERRORS.FORM.REQUIRED").min(2, "ERRORS.FORM.INVALID_FIRSTNAME").max(16, "ERRORS.FORM.INVALID_FIRSTNAME"),
	surname: string().trim().required("ERRORS.FORM.REQUIRED").min(2, "ERRORS.FORM.INVALID_LASTNAME").max(16, "ERRORS.FORM.INVALID_LASTNAME"),
	email: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
	fiscal_code: string().trim().required("ERRORS.FORM.REQUIRED").min(2, "ERRORS.FORM.INVALID_FISCAL_CODE").max(16, "ERRORS.FORM.INVALID_FISCAL_CODE"),
	phone: string().trim().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE"),
	terms: boolean().oneOf([true], "ERRORS.FORM.REQUIRED"),
});
