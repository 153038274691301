import { WHISTLEBLOWER_URL } from "../api/api";
import {ICommonType, IListCount, TApiListSearchParam} from "../types/commons";
import {IReport, IReportComment, IReportDetailed} from "../types/report";

import { ApiService } from "./ApiService";
import {IRegistry} from "../types/registry";

export class CoreApiService extends ApiService {
	constructor() {
		super(WHISTLEBLOWER_URL);
	}

	async countReports(params?: TApiListSearchParam<IListCount>): Promise<number> {
		return this.listCount<any>("report", params);
	}

	async getReports(params?: TApiListSearchParam<IReport>): Promise<IReport[]> {
		return this.listSearch<IReport>("report", params);
	}

	async countReportTypes(params?: TApiListSearchParam<IListCount>): Promise<IListCount> {
		return this.listCount<IListCount>("report-type", params);
	}


	async getReportTypes(params?: TApiListSearchParam<ICommonType>): Promise<ICommonType[]> {
		return this.listSearch<ICommonType>("report-type", params);
	}

	async countSubjectInvolvedTypes(params?: TApiListSearchParam<IListCount>): Promise<IListCount> {
		return this.listCount<IListCount>("subject-involved-type", params);
	}


	async getInvolvedTypes(params?: TApiListSearchParam<ICommonType>): Promise<ICommonType[]> {
		return this.listSearch<ICommonType>("subject-involved-type", params);
	}

	async getInfoReportUser(keycode: string, tenant: string | undefined) {
		return this.get<IReportDetailed>("/report/search/keyCode", { headers: { "x-key-code": keycode, "x-tenant": tenant }});
	}

	async downloadAttachment(id: string) {
		return this.get<any>("/attachment/download/" + id, { responseType: "blob" });
	}

	async getReportView(code: string = "") {
		const report = await this.get<IReport>("/report/view/" + code);
		const detailedReport: IReportDetailed = { report: report, logs: [], comments: [] };
		return detailedReport;
	}

	async changeState(id: string, body: any) {
		return this.post<any, IReport>("/report/changeState/" + id, body);
	}

	async createReportCommentPublic(body: any) {
		return this.post<any, IReportComment>("/report-comment/create-public", body)
	}

	async createReportComment(body: any) {
		return this.post<any, IReportComment>("/report-comment", body);
	}

	async createReportFull(body: any) {
		return this.post<any, IReport>("/report/full", body);
	}

	async uploadFile(id: string, body: FormData) {
		return this.post("/attachment/upload/" + id, body);
	}

	async getReport(id: string, tenant: string) {
		return this.get<IReportDetailed>("/report/" + id, { headers: { "x-tenant": tenant }});
	}
}
