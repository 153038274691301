import React, { useEffect, useState } from "react";
import {EAuthRole, EAuthSessionData, TAuthRole} from "../types/auth";
import {Navigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AuthApiService } from "../services/AuthApiService";
import { updateHaveSubscription, updateIsManual, updateRole, updateUsername } from "../redux/userSlice";
import { IAlert } from "../types/commons";
import { CircularProgress } from "@mui/material";

export default function PrivateRouteSwitch(): React.JSX.Element {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    //const [passedForRefresh, setPassForRefresh] = useState<boolean>(false);
    const [alert, setAlert] = useState<IAlert>({
		show: false,
		severity: "error",
		message: "SIGN_IN_PAGE.SUBMIT_ERROR",
		dismissible: true,
		onClose: () => {
			setAlert({...alert, show: false});
		},
	});
    const haveSubscription = useSelector((state: any) => state.user.haveSubscription); 
    const role = useSelector((state: any) => state.user.role);
    const dispatch = useDispatch();
    const authApiService = new AuthApiService();

    useEffect(() => refresh());

    const refresh = () => {
        if (!role && sessionStorage.getItem(EAuthSessionData.ACCESS_TOKEN)) {
            setIsLoading(true);
            authApiService
                .refreshToken()
                .then((response: any) => {
                    dispatch(updateHaveSubscription(response.haveSubscription));
                    dispatch(updateIsManual(response.isManual));
                    dispatch(updateRole(response.role));
                    dispatch(updateUsername(response.username));
                })
                .catch((error: any) => {
                    if (error === "ERRORS.GENERIC") {
                        setAlert({ ...alert, show: true, message: "ERRORS.GENERIC", severity: "error" });
                    } else {
                        setAlert({ ...alert, show: true, message: error, severity: "error" });
                    }
                })
                .finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }

  

    const verifyRole = (role: TAuthRole | null) => {
        return role === EAuthRole.SUPER_ADMIN || role === EAuthRole.PLATFORM_ADMIN;
    }

    const switchRoute = () => {
        if (!haveSubscription) {
            return <Navigate to="/completa-profilo/acquista" replace/>;
        } else {
            return verifyRole(role) ? <Navigate to="/Homepage" replace/> : <Navigate to="/TabellaSegnalazioniAdmin" replace/>
        }
    }

    const loader = () => {
        return (<div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                    }}
                >
                    <CircularProgress size={100} />
                </div>);
    }

    return (<> { isLoading ? loader() : switchRoute() } </>)
}
