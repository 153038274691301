import * as Yup from "yup";
import {boolean, object, string} from "yup";

import YupPassword from "yup-password";
import {intlPhoneRegex} from "../../../../utils/regex";

YupPassword(Yup);

export default object().shape({
	firstname: string().trim().required("ERRORS.FORM.REQUIRED").min(2, "ERRORS.FORM.INVALID_FIRSTNAME").max(16, "ERRORS.FORM.INVALID_FIRSTNAME"),
	surname: string().trim().required("ERRORS.FORM.REQUIRED").min(2, "ERRORS.FORM.INVALID_LASTNAME").max(16, "ERRORS.FORM.INVALID_LASTNAME"),
	birth_place: string().required("ERRORS.FORM.REQUIRED"),
	birth_date: string().required("ERRORS.FORM.REQUIRED") .test('is-adult', "ERRORS.FORM.MUST_BE_ADULT", function(value) {
		const birthDate = new Date(value);
		const today = new Date();
		let age = today.getFullYear() - birthDate.getFullYear();
		if (today.getMonth() < birthDate.getMonth() || 
				(today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
				age--;
		}
		return age >= 18;
}),
	address: string().required("ERRORS.FORM.REQUIRED"),
	number: string().required("ERRORS.FORM.REQUIRED"),
	postal_code: string().required("ERRORS.FORM.REQUIRED"),
	city: string().required("ERRORS.FORM.REQUIRED"),
	province: string().required("ERRORS.FORM.REQUIRED"),
	state: string().required("ERRORS.FORM.REQUIRED"),
	fiscal_code: string().required("ERRORS.FORM.REQUIRED"),
	email: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
	phone: string().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE").trim("ERRORS.FORM.INVALID_PHONE"),
	terms: boolean().oneOf([true], "ERRORS.FORM.REQUIRED"),
});
