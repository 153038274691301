import {Box, FormControlLabel, IconButton, Radio, RadioGroup, TextField} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import BasicButton from "../../../compontensNuovi/buttons/BasicButton";
import styles from "./TabTre.module.css";
import ClearIcon from "@mui/icons-material/Clear";
import {t} from "i18next";
import {radioStyleSX, radioTextStyleSX} from "../../../assets/CustomStyles";
import MyContext from "../../../context/MyContext";
import {capitalizeFirstLetter} from "../../../utils/helperFunctions";

interface Person {
	code: string;
	name: string;
}

const TabTre = ({ updateReportField, handleTabCompletion, tabIndex }: any) => {
	const { subjectType } = useContext(MyContext)!;
	const [persons, setPersons] = useState<Person[]>([{ code: "", name: "" }]);
	const [description, setDescription] = useState("");
	const [selectedRadio, setSelectedRadio] = useState("");
	const [isTextFieldEnabled, setIsTextFieldEnabled] = useState(false);

	useEffect(() => {
		if (subjectType && subjectType.length > 0) {
			setPersons([{ code: "", name: "" }]);
		}
	}, [subjectType]);

	const handleInputChange = (index: number, event: any, type: string) => {
		setIsTextFieldEnabled(true);
		const newPersons = [...persons];
		newPersons[index] = { ...newPersons[index], [type]: event.target.value };
		setPersons(newPersons);
		setSelectedRadio(event.target.value);
		const updatedRequest = {
			subject_involved: newPersons.map((person) => ({
				subject_involved_type_code: person.code,
				subject_involved: person.name,
			})),
		};

		updateReportField(updatedRequest);
	};

	const handleAddPerson = () => {
		if (subjectType && subjectType.length > 0) {
			setPersons([...persons, { code: subjectType[0].code, name: "" }]);
		}
	};

	const handleRemovePerson = (index: number) => {
		if (index === 0) {
			return;
		}
		const newPersons = [...persons];
		newPersons.splice(index, 1);
		setPersons(newPersons);
		const updatedRequest = {
			subject_involved: newPersons.map((person) => ({
				subject_involved_type_code: person.code,
				subject_involved: person.name,
			})),
		};

		updateReportField(updatedRequest);
	};

	const handleClearInput = (index: number) => {
		const newPersons = [...persons];
		newPersons[index] = { ...newPersons[index], name: "" };
		setPersons(newPersons);
		const updatedRequest = {
			subject_involved: newPersons.map((person) => ({
				subject_involved_type_code: person.code,
				subject_involved: person.name,
			})),
		};

		updateReportField(updatedRequest);
	};

	const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newDescription = event.target.value;
		setDescription(newDescription);
		updateReportField({ description: newDescription });
	};

	useEffect(() => {
		const allFieldsFilled = Boolean(description);
		handleTabCompletion(tabIndex, allFieldsFilled);
	}, [persons, description, handleTabCompletion, tabIndex]);

	if (!subjectType || subjectType.length === 0) {
		return <div>Caricamento...</div>;
	}

	return (
		<div>
			{persons.map((person, index) => (
				<Box key={index} sx={{ mb: 2 }}>
					<RadioGroup
						row
						aria-label="type"
						name="type"
						value={person.code}
						onChange={(event) => {
							setSelectedRadio(event.target.value);
							handleInputChange(index, event, "code");
						}}
					>
						{subjectType.map((subjectType) => (
							<FormControlLabel
								key={subjectType._id}
								value={subjectType.code}
								control={<Radio sx={radioStyleSX} />}
								label={capitalizeFirstLetter(subjectType.code.toLowerCase())}
								sx={radioTextStyleSX}
							/>
						))}
					</RadioGroup>
					<TextField
						style={{ width: "40%" }}
						required
						name="name"
						error={!person.name}
						value={person.name}
						onChange={(event) => handleInputChange(index, event, "name")}
						disabled={!isTextFieldEnabled}
						className={!isTextFieldEnabled ? styles.textFieldDisabled : ""}
						InputProps={
							index !== 0
								? {
										endAdornment: (
											<IconButton
												onClick={() => {
													handleClearInput(index);
													handleRemovePerson(index);
												}}
											>
												<ClearIcon />
											</IconButton>
										),
								  }
								: {}
						}
					/>
				</Box>
			))}
			<BasicButton text={t("FORM_REPORTS.THIRD_TAB.ADD_SUBJECT")} onClick={handleAddPerson} />

			<Box sx={{ margin: "4rem 0 0 0" }}>
				<div className={styles.title}>{t("FACTS_DESCRIPTION")}</div>
				<TextField required fullWidth multiline rows={7} value={description} error={!description} onChange={handleDescriptionChange} />
			</Box>
		</div>
	);
};

export default TabTre;
