import React, {useEffect, useState} from "react";
import {Box, ListItem} from "@mui/material";
import {Log, Translation} from "../../type";
import {t} from "i18next";
import styles from "./Logs.module.css";
import {formatDate} from "../../utils/helperFunctions";
import {IReportLog} from "../../types/report";
import {WorkflowApiService} from "../../services/WorkflowApiService";
import {findTranslationByCode} from "../../utils/translationStateUtils";

interface LogsProps {
	logs: IReportLog[];
}

interface OperationDescriptions {
	[key: string]: string;
}
const operationDescriptions: OperationDescriptions = {
	"NEW COMMENT": t("LOGS.NEW_COMMENT"),
	"CHANGE STATE": t("LOGS.CHANGE_STATUS"),
};

const Logs: React.FC<LogsProps> = ({logs}) => {
	const [sortedLogs, setSortedLogs] = useState<IReportLog[]>([]);
	const [translationStates, setTranslationStates] = useState<Translation[]>([]);
	const workflowApiService = new WorkflowApiService();

	useEffect(() => {
		const sorted = [...logs].sort((a, b) => new Date(b.createdAt ?? Date.now()).getTime() - new Date(a.createdAt ?? Date.now()).getTime());
		setSortedLogs(sorted);
	}, [logs]);

	useEffect(() => {
		workflowApiService.stateTranslation({type: "state"}).then((response: any) => {
			setTranslationStates(response);
		});
	}, []);

	const getOperationDescription = (operationType: string, description?: any) => {
		const message = operationType in operationDescriptions ? operationDescriptions[operationType] : t("LOGS.FIRST_LOG_DESCRIPTION");
		if (operationType === "CHANGE STATE" && description) {
			const state = description.split(`"`);
			const stateTranslated = findTranslationByCode(state[1], translationStates);
			return `${message} ${stateTranslated}`;
		}
		return `${message}`;
	};

	return (
		<>
			<div className={styles.logTitle}>{t("LOGS.TITLE")}</div>
			<Box sx={{backgroundColor: "var(--grey-100)", padding: "35px 30px"}}>
				{sortedLogs.map((log) => {
					// Separare l'username dalla descrizione
					const operationMessage = getOperationDescription(log.operation_type, log.description);

					return (
						<ListItem key={log._id} alignItems="flex-start">
							<div style={{width: "100%"}}>
								<div style={{display: "flex", alignItems: "baseline"}}>
									<div
										style={{
											fontSize: "1.2rem",
											fontWeight: "700",
											marginRight: "5px",
										}}
									>
										{log.username}
									</div>
									<div style={{fontSize: "1rem"}}>{operationMessage}</div>
								</div>
								<div className={styles.dateStyle}>{formatDate(log.createdAt, true)}</div>
							</div>
						</ListItem>
					);
				})}
			</Box>
		</>
	);
};

export default Logs;
