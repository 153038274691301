import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { EAuthRole } from '../types/auth';

  interface UserState {
    haveSubscription: boolean;
    isManual: boolean;
    role: EAuthRole | undefined;
    accessToken: string;
    username: string;
  }
  
  // Define the initial state using that type
  const initialState: UserState = {
    haveSubscription: false,
    isManual: false,
    role: undefined,
    accessToken: '',
    username: ''
  }

  // update access token e delete access token
  export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      updateHaveSubscription: (state, action: PayloadAction<boolean>) => {
        state.haveSubscription = action.payload
      },
      updateIsManual: (state, action: PayloadAction<boolean>) => {
        state.isManual = action.payload
      },
      updateRole: (state, action: PayloadAction<EAuthRole | undefined>) => {
        state.role = action.payload
      },
      updateAccessToken: (state, action: PayloadAction<string>) => {
        state.accessToken = action.payload
      },
      updateUsername: (state, action: PayloadAction<string>) => {
        state.username = action.payload
      },
    },
  })
  
  export const { updateHaveSubscription, updateIsManual, updateRole, updateUsername, updateAccessToken } = userSlice.actions
  
  export default userSlice.reducer