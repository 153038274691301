import React from "react";
import FormAddUtenti from "./FormAddUtenti";
import {Grid} from "@mui/material";

import TableOperators from "./TableOperators";

const UtentiTab = () => {
	const [reloadTable, setReloadTable] = React.useState(false);
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<FormAddUtenti reloadTable={reloadTable} setReloadTable={setReloadTable} />
				</Grid>
				<Grid item xs={12} sm={6}>
					<TableOperators reloadTable={reloadTable} />
				</Grid>
			</Grid>
		</>
	);
};

export default UtentiTab;
