import React, {useEffect, useState} from "react";
import HomeListaAziende from "../HomeListaAziende/HomeListaAziende";
import {ListaAziendeProps} from "../../type";
import {useNavigate, useParams} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Box, Divider, Grid, IconButton, Stack} from "@mui/material";
import Topbar from "../Topbar/Topbar";
import TabMenu from "../../componets/tabMenu/TabMenu";
import ProfiloAzienda from "../DettaglioAzienda/Tabs/ProfiloAzienda";
import UtentiAzienda from "../DettaglioAzienda/Tabs/UtentiAzienda";
import {t} from "i18next";
import {toast_error} from "../../utils/custom-toast";
import {ERegistryType, IRegistry} from "../../types/registry";
import {RegistryApiService} from "../../services/RegistryApiService";
import {Spinner} from "react-bootstrap";
import styles from "../HomeListaAziende/HomeListaAziende.module.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Footer from "../../componets/Footer/Footer";

const DettaglioOrganizzazione: React.FC = () => {
    // Aggiungere chiamata per ottenere aziende
    const { owner} = useParams<{owner: string}>();
    const [selectedTab, setSelectedTab] = useState(0);
    const [ registries, setRegistries ] = useState<IRegistry[]>([]);
    const [ company, setCompany ] = useState<IRegistry>();
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const navigate = useNavigate();
    const registryApiService = new RegistryApiService();

    useEffect(() => {
        // fetch data for info organization
    }, []);

    const handleTabChange = (newValue: number) => {
        setSelectedTab(newValue);
    }

    const tabs = [
        { value: 0, label: t("TOPBAR.PROFILE") },
        { value: 1, label: t("TOPBAR.COMPANIES") },
    ];

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (registries.length === 1) {
            setCompany(registries[0]);
        } else if (registries.length > 1) {
            filterRegistriesForCompany();
        }
    }, [registries]);

    const filterRegistriesForCompany = () => {
        const companyFromRegistries: IRegistry = registries.filter(reg => reg.id_registry_type[0].code === ERegistryType.AZIENDA)[0];
        setCompany(companyFromRegistries);
    }

    const handleBackClick = () => {
        navigate(-1);
    };

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const data = await registryApiService.getRegistryDetail(owner ?? "");
            setRegistries(data);
        } catch (err: any) {
            console.error(err);
            toast_error(err);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <Grid sx={{mt: 1}}>
                <Topbar />
            </Grid>
            {isLoading && !company ? (<Spinner animation="border" />) : (
                <>
                    <div className={styles.labels}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Box>
                                    <Stack direction={"row"}>
                                        <IconButton onClick={handleBackClick} type="button" sx={{ mr: 1}}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                        <div className={styles.subTitle}>{t("ORGANIZATION_DETAIL.TITLE")}</div>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display={"flex"} justifyContent={"flex-start"} marginLeft={"1.5%"}>
                                    <TabMenu value={selectedTab} onChange={handleTabChange} tabs={tabs}/>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <Box marginTop={3}>
                            { selectedTab === 0 ? (
                                <ProfiloAzienda isDetailOfCompany={true} company={company} />
                            ) : (
                                <HomeListaAziende owner={owner} />
                            )}
                        </Box>
                    </div>
                </>
            )}
            <Footer/>
        </>
    );
}
export default DettaglioOrganizzazione;