import * as Yup from "yup";
import {boolean, object, string} from "yup";
import {IAuthSignUp} from "../../../types/auth";
import {intlPhoneRegex} from "../../../utils/regex";
import YupPassword from "yup-password";

YupPassword(Yup);

export default object<IAuthSignUp>().shape({
	email: string().trim().required("ERRORS.FORM.REQUIRED").email("ERRORS.FORM.INVALID_EMAIL"),
	password: string()
		.trim()
		.required("ERRORS.FORM.REQUIRED")
		.min(8, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.max(16, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.minLowercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.minUppercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.minNumbers(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL")
		.minSymbols(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT_SYMBOL"),
	firstName: string()
		.required("ERRORS.FORM.REQUIRED")
		.min(2, "ERRORS.FORM.INVALID_FIRSTNAME")
		.max(16, "ERRORS.FORM.INVALID_FIRSTNAME")
		.trim("ERRORS.FORM.INVALID_FIRSTNAME"),
	lastName: string()
		.required("ERRORS.FORM.REQUIRED")
		.min(2, "ERRORS.FORM.INVALID_LASTNAME")
		.max(16, "ERRORS.FORM.INVALID_LASTNAME")
		.trim("ERRORS.FORM.INVALID_LASTNAME"),
	businessName: string()
		.required("ERRORS.FORM.REQUIRED")
		.min(2, "ERRORS.FORM.INVALID_BUSINESSNAME")
		.max(40, "ERRORS.FORM.INVALID_BUSINESSNAME")
		.trim("ERRORS.FORM.INVALID_BUSINESSNAME"),
	phone: string().required("ERRORS.FORM.REQUIRED").matches(intlPhoneRegex, "ERRORS.FORM.INVALID_PHONE").trim("ERRORS.FORM.INVALID_PHONE"),
	terms: boolean().oneOf([true], "ERRORS.FORM.REQUIRED"),
});
