import React, {useEffect, useRef, useState} from "react";
import styles from "./FormAccordion.module.css";

interface Tab {
	title: string;
	component: React.ReactNode;
	activeTitle?: string;
}

interface AccordionProps {
	tabs: Tab[];
	tabCompletion?: boolean[];
}

const Accordion: React.FC<AccordionProps> = ({ tabs, tabCompletion }) => {
	const [activeTabs, setActiveTabs] = useState<boolean[]>([true].concat(new Array(tabs.length - 1).fill(false)));
	const accordionRef = useRef<HTMLDivElement>(null);

	const toggleTab = (index: number) => {
		if (index === 0 || (tabCompletion && tabCompletion[index - 1])) {
			setActiveTabs((prevActiveTabs) => {
				const newActiveTabs = [...prevActiveTabs];
				newActiveTabs[index] = !newActiveTabs[index];
				return newActiveTabs;
			});
		}
	};

	useEffect(() => {
		scrollToActiveTab();
	}, [activeTabs]);

	const scrollToActiveTab = () => {
		if (accordionRef.current) {
			const activeTabElement = accordionRef.current.querySelector(`[data-tab-index="${activeTabs.lastIndexOf(true)}"]`);
			if (activeTabElement) {
				activeTabElement.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}
		}
	};

	return (
		<div className={styles.accordion} ref={accordionRef}>
			{tabs.map((tab, index) => {
				const isActiveTab = activeTabs[index];
				const isTabComplete = index === 0 || (tabCompletion && tabCompletion[index - 1]);
				return (
					<div key={index}>
						<div
							className={`${styles["accordion-tab"]} ${isActiveTab ? styles.active : ""} ${!isTabComplete ? styles.inactive : ""}`}
							onClick={() => isTabComplete && toggleTab(index)}
							data-tab-index={index}
						>
							{isActiveTab ? tab.activeTitle ?? tab.title : tab.title}
						</div>
						<div className={`${styles["accordion-content"]} ${isActiveTab ? styles.visible : styles.hidden}`}>{tab.component}</div>
					</div>
				);
			})}
		</div>
	);
};

export default Accordion;
