import {IAuthSignUp} from "../../../types/auth";

export default {
    email: "",
    password: "",
    terms: false,
    firstName: "",
    lastName: "",
    businessName: "",
    phone: ""
} as IAuthSignUp;
