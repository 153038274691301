import React, {useEffect, useState} from "react";
import styles from "./HomeListaAziende.module.css";
import {Table} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {Button, IconButton, Pagination} from "@mui/material";
import SearchBar from "../../componets/barraRicerca/SearchBar";
import {toast_error, toast_success} from "../../utils/custom-toast";
import AddButton from "../../componets/Buttons/AddButton";
import {ERegistryType, IRegistry, IRegistryAzienda} from "../../types/registry";
import {RegistryApiService} from "../../services/RegistryApiService";
import {t} from "i18next";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {IResendEmail, ListaAziendeProps} from "../../type";
import isSuperAdminGuard from "../../guards/isSuperAdminGuard";
import isPlatformAdminGuard from "../../guards/isPlatformAdminGuard";
import {AuthApiService} from "../../services/AuthApiService";
import {ToastContainer} from "react-toastify";
import { useSelector } from "react-redux";

const HomeListaAziende: React.FC<ListaAziendeProps> = ({owner}) => {
	const navigate = useNavigate();
	const role = useSelector((state: any) => state.user.role);
	const isSuperAdmin = isSuperAdminGuard(role) || isPlatformAdminGuard(role);
	const [isLoading, setIsLoading] = useState(false);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [searchValue, setSearchValue] = useState("");
	const [azienda, setAzienda] = useState<IRegistry[]>([]);
	const [idOwner, setIdOwner] = useState("");

	const itemsPerPage = 15;
	const authApiService = new AuthApiService();
	const registryApiService = new RegistryApiService();

	useEffect(() => {
		const fetchIdRegistryOwner = async () => {
			if (owner) {
				setIdOwner(owner);
			} else {
				setIsLoading(true);
				try {
					const response = await registryApiService.infoMe();
					if (response._id) {
						setIdOwner(response._id);
					} else {
						toast_error(t("OPERATION_ERROR"));
					}
				} catch (error: any) {
					toast_error(error.message);
				} finally {
					setIsLoading(false);
				}
			}
		};
		fetchIdRegistryOwner();
	}, []);

	const fetchData2 = async () => {
		try {
			setIsLoading(true);
			const data = await registryApiService.countRegistry({
				registry_type: ERegistryType.AZIENDA,
				searchFullText: searchValue,
				searchField: "business_name",
				...(owner && {id_registry_owner: owner}),
			});
			setTotalItems(data);
			if (data && (searchValue.length >= 3 || searchValue.length === 0)) {
				await fetchData();
			}
		} catch (error) {
			setTotalItems(0);
		} finally {
			setIsLoading(false);
		}
	};

	const fetchData = async () => {
		try {
			const data = await registryApiService.searchRegistry({
				page: currentPage,
				limit: itemsPerPage,
				registry_type: ERegistryType.AZIENDA,
				orderBy: "createdAt",
				sort: -1,
				searchFullText: searchValue,
				searchField: "business_name",
				...(owner && {id_registry_owner: owner}),
			});
			setAzienda(data);
		} catch (error: any) {
			toast_error(error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData2();
	}, [currentPage, searchValue]);

	const handleAddAzienda = () => {
		navigate(`/AddAziendaNuova/${idOwner}`);
	};

	const handleSearch = (searchTerm: string) => {
		if (searchTerm.length >= 3) {
			setSearchValue(searchTerm.toLocaleLowerCase().trim());
			setCurrentPage(1);
		} else {
			setSearchValue("");
			setCurrentPage(1);
		}
	};

	const handleTableRowClick = (id: any) => {
		if (isSuperAdmin && id) {
			navigate(`/CompanyDetail/${id}`);
		}
	};

	const resendEmail = async (isManual: boolean, email?: string) => {
		try {
			if (email) {
				const resendEmailDto: IResendEmail = {email: email, type: isManual ? "userEmailManual" : "verifyMail"};
				await authApiService.resendEmail(resendEmailDto);
				toast_success(t("RESEND_EMAIL.EMAIL_SENDED"));
			} else {
				toast_error(t("RESEND_EMAIL.EMAIL_NOT_PRESENT"));
			}
		} catch (err: any) {
			console.error(err);
			toast_error(err);
		}
	};

	return (
		<>
			<ToastContainer />
			<div className={styles.labels}>
				<div className={styles.subTitle}>{t("COMPANY_TABLE.TITLE")}</div>
				<div style={{marginBottom: "20px"}}>
					<SearchBar onSearch={handleSearch} placeholder={t("SEARCH")} />
					{/* ADD AZIENDA */}
					<AddButton onClick={handleAddAzienda} text={t("COMPANY_TABLE.ADD_COMPANY")} />
				</div>
				<Table hover>
					<thead
						style={{
							backgroundColor: "var(--grey-100)",
							color: "black",
							borderBottom: "2px var(--grey-100) solid",
						}}
					>
						<tr>
							<th className={styles.tableHeaders}>{t("COMPANY_TABLE.TABLE_TITLE.COMPANY")}</th>
							<th className={styles.tableHeaders}>VAT</th>
							<th className={styles.tableHeaders}>Email</th>
							<th className={styles.tableHeaders}>{t("PHONE")}</th>
							<th className={styles.tableHeaders}>Tenant</th>
							<th className={styles.tableHeaders}>{t("COMPANY_TABLE.RESEND_EMAIL")}</th>
							<th className={styles.tableHeaders}>Detail</th>
						</tr>
					</thead>
					<tbody>
						{isLoading ? (
							<tr>
								<td colSpan={4} className={styles.loadingRow}>
									Loading...
								</td>
							</tr>
						) : (
							azienda.length > 0 &&
							azienda?.map((azienda) => (
								<tr key={azienda._id} className={`${styles.rowStyle} ${styles.tableRow}`}>
									<td className={styles.rowStyle}>{azienda.business_name}</td>
									<td className={styles.rowStyle}>{azienda.vat_number}</td>
									<td className={styles.rowStyle}>
										{azienda.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "EMAIL")?.contact}
									</td>
									<td className={styles.rowStyle}>
										{azienda.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "PHONE")?.contact}
									</td>
									<td className={styles.rowStyle}>{azienda.tenant}</td>
									<td className={styles.rowStyle}>
										<Button
											sx={{textTransform: "none"}}
											onClick={() =>
												resendEmail(
													azienda.isManual,
													azienda.id_contact.find((contact: any) => contact.id_contact_type && contact.id_contact_type.code === "EMAIL")?.contact,
												)
											}
										>
											{t("COMPANY_TABLE.RESEND_EMAIL")}
										</Button>
									</td>
									<td className={styles.rowStyle}>
										<IconButton onClick={() => handleTableRowClick(azienda._id)} type="button">
											<VisibilityIcon />
										</IconButton>
									</td>
								</tr>
							))
						)}
						{azienda.length === 0 && (
							<>
								<tr key={1} className={`${styles.rowStyle} ${styles.tableRow}`}>
									<td className={styles.rowStyle}>{t("SETTINGS.TAB_USERS.USER_TABLE.NO_DATA")}</td>
								</tr>
							</>
						)}
					</tbody>
				</Table>
				{!isLoading ? (
					<Pagination
						count={Math.ceil(totalItems / itemsPerPage)}
						page={currentPage}
						onChange={(_, page) => setCurrentPage(page)}
						shape="rounded"
						className={styles.pagination}
						showFirstButton
					/>
				) : null}
			</div>
		</>
	);
};

export default HomeListaAziende;
