import sharedStyles from "../AuthPages/components/AuthDefaultLayout/Shared.module.css";
import {Button, Form, InputGroup} from "react-bootstrap";
import BasicButton from "../../compontensNuovi/buttons/BasicButton";
import {useState} from "react";
import {t} from "i18next";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Formik, FormikTouched} from "formik";
import * as Yup from "yup";
import {toast_error} from "../../utils/custom-toast";
import {ToastContainer} from "react-toastify";
import {API_AUTH_NEW_PASSWORD, API_AUTH_RECOVERY_PASSWORD} from "../../api/api";
import AuthDefaultLayout from "../AuthPages/components/AuthDefaultLayout/AuthDefaultLayout";
import { AuthApiService } from "../../services/AuthApiService";

const ResetPasswordSchema = Yup.object().shape({
	newPassword: Yup.string()
		.trim()
		.required("ERRORS.FORM.REQUIRED")
		.min(8, "ERRORS.FORM.SHOULD_BE_AT_LEAST_8_CHARACTERS")
		.max(16, "ERRORS.FORM.SHOULD_BE_AT_MOST_16_CHARACTERS")
		.minLowercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
		.minUppercase(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT")
		.minNumbers(1, "ERRORS.FORM.INVALID_PASSWORD_FORMAT"),
	newPasswordConfirm: Yup.string()
		.oneOf([Yup.ref("newPassword")], "ERRORS.FORM.INVALID_PASSWORD_MATCH")
		.required("ERRORS.FORM.REQUIRED"),
});

const ResetPassword = () => {
	const [mostraPwd, setMostraPwd] = useState(false);
	const { token } = useParams();
	const navigate = useNavigate();

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const change = searchParams.get("change");
	const authApiService = new AuthApiService();

	// const authApiService = new AuthApiService();

	const recoverPassword = async (values: {newPassword: string; newPasswordConfirm: string}) => {
		const payload = {pwd: values.newPassword};
		const header = {
			headers: {
				"x-access-token": token
			},
		};
		const apiEndPoint = change === "true" ? API_AUTH_NEW_PASSWORD : API_AUTH_RECOVERY_PASSWORD;
		try {

			const data = change === "true" ? await authApiService.newPassword(values.newPassword, token ?? "") : await authApiService.resetPassword(values.newPassword, token ?? "");
			if (data) {
				navigate("/auth/login");
			}
		} catch (error: any) {
			toast_error(error);
		}
	};

	return (
		<>
			<AuthDefaultLayout>
				<ToastContainer />
				<Formik initialValues={{newPassword: "", newPasswordConfirm: ""}} validationSchema={ResetPasswordSchema} onSubmit={recoverPassword}>
					{({handleSubmit, values, errors, touched, handleChange, handleBlur, isValid}) => {
						const formIsTouched = Object.keys(touched).some(
							(field) => touched[field as keyof FormikTouched<{newPassword: string; newPasswordConfirm: string}>],
						);
						const isSubmitDisabled = !formIsTouched || !isValid;
						return (
							<Form onSubmit={handleSubmit}>
								{change === "true" ? (
									<div className={sharedStyles.labels}>{t("NEW_PASSWORD")}</div>
								) : (
									<div className={sharedStyles.labels}>{t("RESET_PASSWORD")}</div>
								)}
								<div className={sharedStyles.container}>
									<InputGroup className={sharedStyles.inputGroupWithButton}>
										<Form.Control
											id="newPassword"
											name="newPassword"
											type={mostraPwd ? "text" : "password"}
											placeholder={t("NEW_PASSWORD")}
											value={values.newPassword}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={touched.newPassword && !!errors.newPassword}
										/>
										<Button onClick={() => setMostraPwd(!mostraPwd)} variant={"outline-light"}>
											{mostraPwd ? <VisibilityOff /> : <Visibility />}
										</Button>
										<Form.Control.Feedback type="invalid">{touched.newPassword && errors.newPassword && t(errors.newPassword)}</Form.Control.Feedback>
									</InputGroup>
									<InputGroup className={sharedStyles.inputGroupWithButton}>
										<Form.Control
											id="newPasswordConfirm"
											name="newPasswordConfirm"
											type={mostraPwd ? "text" : "password"}
											placeholder={t("CONFIRM_PASSWORD")}
											value={values.newPasswordConfirm}
											onChange={handleChange}
											onBlur={handleBlur}
											isInvalid={touched.newPasswordConfirm && !!errors.newPasswordConfirm}
										/>
										<Button onClick={() => setMostraPwd(!mostraPwd)} variant={"outline-light"}>
											{mostraPwd ? <VisibilityOff /> : <Visibility />}
										</Button>
										<Form.Control.Feedback type="invalid">
											{touched.newPasswordConfirm && errors.newPasswordConfirm && t(errors.newPasswordConfirm)}
										</Form.Control.Feedback>
									</InputGroup>
								</div>
								<BasicButton
									text={t("CONFIRM")}
									maxWidth={"100%"}
									type="submit"
									disabled={isSubmitDisabled}
									backgroundColor={isSubmitDisabled ? "var(--grey-200)" : undefined}
								/>
							</Form>
						);
					}}
				</Formik>
			</AuthDefaultLayout>
		</>
	);
};

export default ResetPassword;
